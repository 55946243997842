.ce-inline-tool .selectionList {
  position: absolute;
  top: 35px;
  left: 0;
  max-height: 250px;
  overflow-y: scroll;
}

.ce-inline-tool .selectionList .selection-list-wrapper {
  width: 50px;
  background: #fff;
  border: 1px solid #eaeaea;
}

.ce-inline-tool
  .selectionList
  .selection-list-wrapper
  .selection-list-option:hover {
  background-color: #eff2f5;
}

.ce-inline-tool .selectionList .selection-list-wrapper .selection-list-option {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eaeaea;
}

.ce-inline-tool
  .selectionList
  .selection-list-wrapper
  .selection-list-option-active {
  background-color: #eff2f5;
}

.ce-inline-tool .ce-inline-tool--font {
  display: flex;
  flex-direction: row;
  position: relative;
}

.ce-inline-tool.button-wrapper-text {
  width: 30px;
}
